import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import AddToCartButton from 'components/add-to-cart-button'
import Container from 'components/container'
import IconCheck from 'components/icons/check'
import IconFlask from 'components/icons/flask'
import IconCLIA from 'components/icons/clia'
import {
  Hero,
  Img,
  H1,
  Intro,
  Contents,
  Price,
  ImgMobile,
  Features,
  MobileHideButtonWrapper,
} from './styled'
import useVasectomyKitProduct from 'hooks/use-vasectomy-kit-product'
import { formatCurrency } from 'utils/format'

type QueryResult = {
  hero: FluidImage
  heroTablet: FluidImage
  heroMobile: FluidImage
}

const query = graphql`
  query {
    hero: file(relativePath: { eq: "vasectomy-test-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, webpQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroMobile: file(relativePath: { eq: "vasectomy-test-hero-mobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 375, webpQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const VasectomyHero = () => {
  const data = useStaticQuery<QueryResult>(query)
  const product = useVasectomyKitProduct()

  return (
    <Hero>
      <Img
        // @ts-ignore
        fluid={{
          ...data.hero.childImageSharp.fluid,
        }}
        fadeIn={false}
      />
      <ImgMobile
        // @ts-ignore
        fluid={{
          ...data.heroMobile.childImageSharp.fluid,
        }}
        fadeIn={false}
      />

      <Container>
        <H1>Vasectomy Test</H1>
        <Intro>
          <p>
            Testing the success of your vasectomy is the only way to know for
            sure that you are no longer fertile and not able to conceive. The
            <span translate="no"> Fellow </span>Vasectomy Test for the presence
            of sperm in your sample. If no sperm are found, American Urological
            Association guidelines recommend that you can stop using other forms
            of contraception.
          </p>
          <Contents isExpanded={true}>
            <li>
              <strong>Convenient. </strong>Provide your sample in the comfort of
              your home and send it back in our discreet, pre-paid mailer.
            </li>
            <li>
              <strong>
                Clinically validated.<sup>§</sup>
              </strong>
              Specimens are analyzed to identify the presence of sperm in our
              CLIA-certified lab.
            </li>
            <li>
              <strong>Fast. </strong>Results typically arrive via secure link
              within 5–7 business days of when you ship your sample.
            </li>
          </Contents>
          {
            // Unfortunately have to hard code subscription price until
            // we can query stripe prices via graphql
          }
          <Price>${formatCurrency(product.price)}</Price>
          <MobileHideButtonWrapper>
            <AddToCartButton product={product} CTA="Add to cart" />
          </MobileHideButtonWrapper>
          <Features>
            <li>
              <IconCheck /> Top doctors approve
            </li>
            <li>
              <IconFlask /> Privacy & Security
            </li>
            <li>
              <IconCLIA /> CLIA-certified lab
            </li>
          </Features>
        </Intro>
      </Container>
    </Hero>
  )
}

export default VasectomyHero
